.interactive-demo-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  margin: 0vw;
}

.interactive-demo-portals-container {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.interactive-demo-left-vertical-container {
  display: flex;
  flex-direction: column;
  width: 50vw;
  height: 100vh;
}

.interactive-demo-right-vertical-container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
}

.interactive-demo-admin-frame {
  display: flex;
  height: 100%;
  width: 50vw;
  border-right: 0.05vw solid cornflowerblue;
}

.interactive-demo-warehouse-frame {
  display: flex;
  height: 100%;
  width: 50vw;
}
