.default-demo-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  margin: 0vw;
}

.default-demo-store-container {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: column;
  width: 50vw;
  height: 100vh;
}

.default-demo-store-frame {
  display: flex;
  border-right: 0.05vw solid cornflowerblue;
  width: 50vw;
  height: 100%;
}

.default-demo-left-vertical-container {
  display: flex;
  flex-direction: column;
  width: 50vw;
  height: 100vh;
}

.default-demo-right-vertical-container {
  display: flex;
  flex-direction: column;
  width: 50vw;
  height: 100vh;
}

.default-demo-admin-frame {
  display: flex;
  height: 50vh;
  border-bottom: 0.05vw solid cornflowerblue;
}

.default-demo-warehouse-frame {
  display: flex;
  height: 50vh;
}
